<template>
    <v-container fluid>
        <v-alert
        dense
        outlined
        type="error"
        v-if="Object.keys(validation_errors).length > 0"
        >
        <ul>
            <li v-for="(errors, field) in validation_errors" :key="field">
                {{ field }}
                <ul>
                    <li v-for="(error, sl) in errors" :key="sl">
                        {{ error }}
                    </li>
                </ul>
            </li>
        </ul>
        </v-alert>
        <v-row>
            <v-col cols="12" class="py-1">
                <v-card :elevation="1">
                    <v-toolbar color="white" :elevation="1" height="30px">
                        <v-toolbar-title class="subtitle-2"
                            >Designation Entry</v-toolbar-title
                        >
                    </v-toolbar>

                    <v-card-text class="py-0">
                        <v-form
                            @submit.prevent="saveDesignation"
                            ref="designationForm"
                            class="custom-form"
                        >
                            <v-row class="justify-center">
                                <v-col cols="12">
                                    <v-row>
                                        <v-col cols="1" class="py-1 caption"
                                            >Name</v-col
                                        >
                                        <v-col cols="2" class="py-0">
                                            <v-text-field
                                                dense
                                                outlined
                                                hide-details
                                                :rules="[(v) => !!v]"
                                                v-model="designation.name"
                                                :error-messages="validation_errors.name"
                                            ></v-text-field>
                                        </v-col>

                                        <v-col
                                            cols="1"
                                            class="py-1 caption text-center"
                                            >Rank</v-col
                                        >
                                        <v-col cols="1" class="py-0">
                                            <v-text-field
                                                dense
                                                outlined
                                                hide-details
                                                type="number"
                                                min="0"
                                                :rules="[(v) => !!v]"
                                                v-model.number="designation.rank"
                                                :error-messages="validation_errors.rank"
                                            ></v-text-field>
                                        </v-col>

                                        <v-col cols="3" class="py-0">
                                            <v-btn
                                                type="submit"
                                                height="26px"
                                                dark
                                                color="light-blue darken-2"
                                                :elevation="1"
                                                class="mr-1"
                                                :loading="loading"
                                                >Save</v-btn
                                            >
                                            <v-btn
                                                height="26px"
                                                dark
                                                color="deep-orange"
                                                :elevation="1"
                                                @click="resetForm"
                                                >Cancel</v-btn
                                            >
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12" class="pb-0">
                <v-data-table
                    class="custom-data-table elevation-1"
                    dense
                    :headers="designationHeaders"
                    :items="$store.getters['designation/designations']"
                    :search="searchDesignation"
                >
                    <template v-slot:top>
                        <v-toolbar
                            dense
                            color="white"
                            :elevation="1"
                            height="30px"
                        >
                            <v-toolbar-title class="subtitle-2"
                                >Designation List</v-toolbar-title
                            >
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-form class="custom-form">
                                <v-text-field
                                    outlined
                                    dense
                                    hide-details
                                    placeholder="Search Designation"
                                    append-icon="mdi-magnify"
                                    style="width: 300px"
                                    v-model="searchDesignation"
                                >
                                </v-text-field>
                            </v-form>
                        </v-toolbar>
                    </template>
                    <template v-slot:[`item.action`]="{ item }">
                        <v-tooltip bottom v-if="$store.state['user'].role != 'User'">
                            <template v-slot:activator="{ on }">
                                <v-icon
                                    small
                                    @click="editDesignation(item)"
                                    color="primary"
                                    v-on="on"
                                    >mdi-circle-edit-outline</v-icon
                                >
                            </template>
                            <span>Edit</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="$store.state['user'].role == 'Admin'">
                            <template v-slot:activator="{ on }">
                                <v-icon
                                    small
                                    @click="deleteDesignation(item)"
                                    color="error"
                                    v-on="on"
                                    >mdi-delete-circle-outline</v-icon
                                >
                            </template>
                            <span>Delete</span>
                        </v-tooltip>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "Designations",

    data() {
        return {
            designationHeaders: [
                { text: "Name", value: "name" },
                { text: "Rank", value: "rank" },
                { text: "Action", value: "action" },
            ],
            searchDesignation: "",

            designation: {
                name: "",
                rank: null,
            },

            editId: null,
            validation_errors: {},
            loading: false,
        };
    },

    created() {
        this.$store.dispatch("designation/getDesignations");
    },

    methods: {
        async saveDesignation() {
            if (!this.$refs.designationForm.validate()) return;

            this.loading = true;

            let data = {
                url: "/add-designation",
                designation: this.designation,
            };

            if (this.editId) {
                data.url = "/update-designation";
                data.designation.id = this.editId;
            }

            let resObj = await this.$store.dispatch(
                "designation/saveDesignation",
                data
            );
            
             if(resObj.hasOwnProperty("validation_errors")){
                this.validation_errors = resObj.validation_errors;
            }
            this.loading = false;

            if(resObj.isSuccess) {
                this.resetForm();
            }
        },
        editDesignation(designation) {
            this.designation.name = designation.name;
            this.editId = designation.id;
        },
        deleteDesignation(designation) {
            if (!confirm("Are you sure?")) return;

            this.$store.dispatch(
                "designation/deleteDesignation",
                designation.id
            );
        },
        resetForm() {
            this.designation.name = "";
            this.designation.rank = null;
            this.editId = null;
            this.$refs.designationForm.resetValidation();
            this.validation_errors = {};
        },
    },
};
</script>

<style>
</style>
